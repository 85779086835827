import {Card, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../../utils/baseApiUrl';
import Element from './element'
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';;

export default function Object(props) {
  const [object, setObject] = React.useState(null);
  const [elements, setElements] = React.useState(null);
  const [elementToDraws, setElementsToDraws] = React.useState([])
  const [tempElement, setTempElement] = React.useState(null)

  const drawElement = (element) => {
    setTempElement(element)
  }

  React.useEffect(() => {
    if (tempElement != null) {
      let newElementToDraws = elementToDraws.filter((item) => {
        return item.id != tempElement.id;
      });
  
      let toDraw = {
          url: tempElement.url,
          x: tempElement.x + object.X,
          y: tempElement.y + object.Y,
          id: tempElement.id,
          position: tempElement.position,
      }
  
      newElementToDraws.push(toDraw);
  
      setElementsToDraws(newElementToDraws, object.position);
  
      const objectToDraw = {
        elements: newElementToDraws,
        object_id: props.id,
        group_id: object.object_groups_id,
      }
  

      props.drawObject(objectToDraw)
    }
  }, [tempElement])

  React.useEffect(() => {
    if (props.id != undefined && object == null) {
      axios.get(baseApiUrl + 'api/object/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        console.log(response.data)
        setObject(response.data);
      })

      axios.get(baseApiUrl + 'api/object/' + props.id + '/element', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;

        setElements(data)
      })
    }
  }, []);
  
  let elementComponents = null
  if (elements != null) {
    elementComponents = elements.map((item) => {
      return (<Col md={4} lg={3}><Element token={props.token} id={item.id} key={item.id} drawElement={drawElement} /></Col>)
    })
  }

  return (
    <>
        <Card>
            <Card.Body>
            <Card.Title>{object != null ? object.name : ''}</Card.Title>
            <Row>{elementComponents}</Row>
            </ Card.Body>
        </Card>
    </>
  );
}
