import React from 'react'
import Generate from '../components/generate/generate';

const Test = () => {
    const [showGenerate, setShowGenerate] = React.useState(false)
    const [token, setToken] = React.useState(null)

    React.useEffect(() => {
        if (window) { 
           let t = window.localStorage.getItem('token')
           
           if (t == null) {
               window.location.href = '/login'
           } else {
                setToken(t)
                setShowGenerate(true)
           }
        }
    }, []);

  return (
      <>
        {showGenerate ? <Generate token={token}/> : null}
      </>
  );
}

export default Test;
