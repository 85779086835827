import React from 'react'
import baseApiUrl from '../../utils/baseApiUrl';
import 'bootstrap/dist/css/bootstrap.min.css';
import { FloatingLabel, Card, Form, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import Object from './object';
import Layout from '../Layout';

const Generate = (props) => {
    const [viewPortOptions, setViewPortOptions] = React.useState(null);
    const [viewPort, setViewPort] = React.useState(null);
    const [objects, setObjects] = React.useState([]);
    const [groups, setGroups] = React.useState(null);
    const [groupOptions, setGroupOptions] = React.useState(null);
    const [objectsToDraw, setObjectsToDraw] = React.useState([]);
    const [tempObject, setTempObject] = React.useState(null);

    React.useEffect(() => {
        if(props.token != null) {
            axios.get(baseApiUrl + 'api/viewport', {
                headers: {
                    Authorization: 'Bearer ' + props.token,
                }
            })
            .then((response) => {
                let data = response.data;
                const items = data.map((item) => {
                    return (<option key={item.id} value={item.id}>{item.name}</option>)
                })
                const options = (<FloatingLabel controlId="floatingSelectGrid" label="Viewport"><Form.Select aria-label="Default select example" onChange={handleChangeViewPort}>
                <option value={0}>Open this select menu</option>
                    {items}
                </Form.Select></FloatingLabel>)
                setViewPortOptions(options);
            })
        }
    }, []);

    React.useEffect(() => {
        if (groups != null) {
            let options = groups.map((group) => {
                let items = group.objects.map((item) => {
                    return (<option key={item.id} value={item.id}>{item.name}</option>) 
                })
                return (<Col key={group.id}><FloatingLabel controlId="floatingSelectGrid" label={group.name}><Form.Select aria-label="Default select example" onChange={(event) => handleChangeGroup(event, group.id)}>
                <option value={0}>Open this select menu</option>
                    {items}
                </Form.Select></FloatingLabel></Col>)
            })

            setGroupOptions(options);
        }
    }, [groups])

    React.useEffect(() => {
        if (tempObject != null) {
            let newObjectToDraw = objectsToDraw.filter((item) => {
                return item.group_id != tempObject.object_groups_id
            })

            setObjectsToDraw(newObjectToDraw)

            let newObject = objects.filter((item) => {
                return item.object_groups_id != tempObject.object_groups_id
            })

            newObject.push(tempObject)

            setObjects(newObject)
        }
    }, [tempObject])

    const handleChangeGroup = (event, id) => {
        let objectId = event.target.value;

        axios.get(baseApiUrl + 'api/object/' + objectId, {
            headers: {
                Authorization: 'Bearer ' + props.token,
            }
        }).then((response) => {
            let data = response.data
            setTempObject(data);
        })
    }

    const drawObject = (object) => {
        let newObjectToDraw = objectsToDraw.filter((item) => {
            return item.object_id != object.object_id
        })
        
        newObjectToDraw.push(object)

        setObjectsToDraw(newObjectToDraw);

        draw(newObjectToDraw)
    }

    const draw = (newObjectToDraws) => {
        let img = document.getElementById('mockup');
        let canvas = document.getElementById('canvas');   
        let ctx = canvas.getContext('2d');
        ctx.clearRect(0,0,  viewPort.width, viewPort.height);
        ctx.canvas.width  = viewPort.width;
        ctx.canvas.height = viewPort.height;

        let elementToDraw = []
        newObjectToDraws.map((items) => {
            items.elements.map((item) => {
                elementToDraw.push(item)
            })
        })
        const sortedElements = elementToDraw.sort((a, b) => {
            return (a.position > b.position) ? 1 : ((a.position < b.position) ? -1 : 0)
        })

        sortedElements.forEach((item) => {
            let image = new Image;
            image.src = item.url;
            ctx.drawImage(image, item.x, item.y);
        })

        ctx.strokeRect(0, 0,  viewPort.width, viewPort.height); 
    }

    const handleChangeViewPort = (event) => {
        let viewPortId = event.target.value
        setObjects([])
        setGroups(null)
        setGroupOptions(null)
        setObjectsToDraw([])
        setTempObject(null)
        if (viewPortId != 0) {
            axios.get(baseApiUrl + 'api/viewport/' + viewPortId, {
                headers: {
                    Authorization: 'Bearer ' + props.token,
                  }
            })
            .then((response) => {
                let data = response.data
                setViewPort(data)
                let canvas = document.getElementById('canvas');  
        
                let ctx = canvas.getContext('2d');
                ctx.clearRect(0,0,  data.width, data.height);

                ctx.canvas.width  = data.width;
                ctx.canvas.height = data.height;

                ctx.strokeRect(0, 0,  data.width, data.height);
            })
            .catch((error) => {
                setViewPort(null)
            })

            axios.get(baseApiUrl + 'api/viewport/' + viewPortId + '/group', {
                headers: {
                    Authorization: 'Bearer ' + props.token,
                  }
            })
            .then((response) => {
                let data = response.data;
                setGroups(data)
            })
        } else {
            setViewPort(null)
        }
    }

    let objectComponent = null;

    if (objects != null) {
        objectComponent = objects.map((item) => {
            return ( <Col sm={12}><Object token={props.token} id={item.id} key={item.id} drawObject={drawObject}/></Col>)
        })
    }

  return (
      <Layout>
        <Card style={{padding: 20 + 'px'}}>
            <Card.Title>Generate image</Card.Title>
            <Card.Body>
            <Form.Group controlId="formFile" className="mb-3">
                {viewPortOptions}
            </Form.Group>
            <Form.Group controlId="formFile" className="mb-3">
                <Row>{groupOptions}</Row>
                
            </Form.Group>
            <Row>
                {objectComponent}
            </Row>
            <canvas style={{width: 100 + '%'}} id="canvas" ></canvas>
            </Card.Body>
        </Card>
        </Layout>
  );
}

export default Generate;
