import React from 'react';
import { Button, Card, Row, Col ,InputGroup, Form} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Element(props) {
  const [element, setElement] = React.useState(null);
  const [name, setName] = React.useState('');
  const [components, setComponents] = React.useState(null);
  const [attributes, setAttributes] = React.useState([]);
  const [defaultObject, setDefault] = React.useState(false);
  const [edit, setEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(null)

  React.useEffect(() => {
    if (props.id != undefined && element == null) {
      axios.get(baseApiUrl + 'api/element/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        setElement(response.data);
      })

      axios.get(baseApiUrl + 'api/element/' + props.id + '/attribute', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;

        setAttributes(data);
      })
    }
  });

  const handleChangeName = (event) => {
    setName(event.target.value);
  }

  
  const handleDelete = (id) => {
    axios.delete(baseApiUrl + 'api/attribute/' + id, {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    })
    .then((response) => {
        window.location.reload(false);
    })
  }
  const handleChangeDefault = (event) => {
    setDefault(event.target.checked);
  }

  const handleUndo = () => {
    setEdit(false)
    setEditId(null)
  }

  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData() 
    data.append('element_id', props.id);
    data.append('name', name);
    data.append('default', defaultObject ? 1 : 0)

    if (edit) {
      let putData = {
        name: name,
        default: defaultObject ? 1: 0,
      }
      axios.put(baseApiUrl + 'api/attribute/' + editId, putData, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })
    } else {
      axios.post(baseApiUrl + 'api/attribute', data, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })
    }
  }

  const items = attributes.map((item) => {
    return (
      <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.default ? 'Yes' : 'No'}</td>
      <td><Link key={item.id} to={"/attribute/" + item.id}><Button variant="info" disabled={edit}>View</Button></Link>
      {edit && editId == item.id ? <Button variant="success" onClick={() => handleUndo()}>UNDO</Button> : <Button variant="success" disabled={edit} onClick={() => handleEdit(item.id)}>EDIT</Button>}
        <Button variant="danger" disabled={edit} onClick={() => handleDelete(item.id)}>DELETE</Button>
      </td>
    </tr>
    )
  })

  return (
    <>
      <div style={{ backgroundColor: 'white', padding: 40 + 'px' }}>
      <Row>
       <Col md={6}>
          
              <h1>{element ? element.name : ''}</h1>
              </Col>
       <Col md={6}>
       <table className="table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Default</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table>
         </Col>
      </Row>
          </div>
          <Form onSubmit={handleSubmit}>
              <Row>
          <Col md={4}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeName}
                  type="text"
                  value={name}
                  />
              </InputGroup>
          </Col>
          <Col md={4}>
          <InputGroup>
            <label style={{paddingTop: 10 + 'px'}}><input type="checkbox" onChange={handleChangeDefault}/> Set object as default</label>
            
          </InputGroup>
          </Col>
          <Col>
            <Button variant="primary" type="submit">
                {edit ? 'Update Attribute' : 'Add Attribute'}
            </Button>
            <Link to={element != null ? '/object/' + element.object_id : ''}><Button variant="secondary">Back</Button></Link>
            </Col>
        </Row>
      
      </Form>
    </>
  );
}
