import React from 'react';
import { Button, ButtonGroup, Card, Row, Col ,Images, Form, FloatingLabel} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../../utils/baseApiUrl';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Element(props) {
  const [element, setElement] = React.useState(null);
  const [attributes, setAttributes] = React.useState(null);
  const [attribute, setAttribute] = React.useState(0);
  const [images, setImages] = React.useState(null);

  React.useEffect(() => {
    if (props.id != undefined && element == null) {
      axios.get(baseApiUrl + 'api/element/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        setElement(response.data);
      })

      axios.get(baseApiUrl + 'api/element/' + props.id + '/attribute', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;
        const items = data.map((item) => {
          return (<option key={item.id} value={item.id}>{item.name}</option>)
        })
        setAttributes(items);
      })
    }
  }, []);

    const handleChangeImage = (id) => {
        axios.get(baseApiUrl + 'api/image/' + id, {
          headers: {
            Authorization: 'Bearer ' + props.token,
          }
        })
        .then((response) => {
            let data = response.data;

            let url = baseApiUrl + data.image;
            url = url.replace('public','storage');

            let drawData = {
                url: url,
                x: element.X,
                y: element.Y,
                id: element.id,
                position: element.position,
            }

            props.drawElement(drawData)
        })
    }

    const handleChange = (event) => {
        let value = event.target.value
        setAttribute(value);

        if (value != 0) {
            axios.get(baseApiUrl + 'api/attribute/' + value + '/image', {
              headers: {
                Authorization: 'Bearer ' + props.token,
              }
            })
            .then((response) => {
                let data = response.data;

                setImages(data)
            })
        } else {
            setImages(null)
        }
    }

    let options = null;
    if (images != null) {
      options = images.map((item) => {
          let url = item.image;
          url = url.replace('public','storage');

          return (<label key={item.id} style={{margin: 5 + 'px'}}><input type="radio" variant="secondary" onClick={() => handleChangeImage(item.id)} value={item.id} name={'element_' + props.id}/><img src={baseApiUrl + url} width={50}/></label>)
      })
    }

  return (
    <>
        <FloatingLabel controlId="floatingSelectGrid" label={element != null ? element.name : null}>
      <Form.Select onChange={handleChange} aria-label="Floating label select example">
        <option value={0}>Open this select menu</option>
        {attributes}
      </Form.Select>
    </FloatingLabel>
    {options}
    </>
  );
}
