import React from 'react';
import baseApiUrl from '../utils/baseApiUrl';
import Viewport from './viewport';
import axios from 'axios';
import { Button, Card, Row, Col, InputGroup, Form } from 'react-bootstrap';
import { Link } from "react-router-dom";

const ViewportList = (props) => {
  const [viewports, setViewports] = React.useState([]);
  
  React.useEffect(() => {
    axios.get(baseApiUrl + 'api/viewport', {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    }).then((response) => {
      console.log(response);
      setViewports(response.data);
    });
  }, []);

  let components = null
  if (props.token != undefined) {
    components = viewports.map((item) => (
      <Viewport token={props.token} key={item.id} id={item.id} name={item.name} width={item.width} height={item.height}/>
    ));
  }
  return (
    <>
      <Row style={{justifyContent: 'center'}}>{components}</Row>
      <div style={{ padding: 20 + 'px' }}>
        <Link to="/add-viewport">
          <Button>Add Viewport</Button>
        </Link>
      </div>
    </>
  );
};

export default ViewportList;
