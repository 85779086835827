import React from 'react';
import { Link, useParams } from "react-router-dom";
import { Button, Card, Row, Col ,InputGroup, Form} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Object(props) {
  const [object, setObject] = React.useState(null);
  const [isDown, setIsDown] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [startY, setStartY] = React.useState(0);
  const [posX, setPosX] = React.useState(0);
  const [posY, setPosY] = React.useState(0);
  const [name, setName] = React.useState('');
  const [position, setPosition] = React.useState(0);
  const [components, setComponents] = React.useState(null);
  const [elements, setElements] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(null)
  React.useEffect(() => {
    drawRectangle()
    if (props.id != undefined && object == null) {
      axios.get(baseApiUrl + 'api/object/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        setObject(response.data);
      })

      axios.get(baseApiUrl + 'api/object/' + props.id + '/element', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;

        setElements(data);
      })
    }
  });

  React.useEffect(() => {
    if(document.getElementById("canvas") != null){  
      drawRectangle()
      let canvas = document.getElementById('canvas');
      let ctx = canvas.getContext('2d');
      ctx.strokeStyle = 'red';
      ctx.strokeRect(startX, startY,  posX, posY);
  }
}, [startX, startY, posX, posY, position, name])

  const handleDelete = (id) => {
    axios.delete(baseApiUrl + 'api/element/' + id, {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    })
    .then((response) => {
        window.location.reload(false);
    })
  }

  const drawRectangle = () => {
    let canvas = document.getElementById('canvas');
    let ctx = canvas.getContext('2d');
    ctx.strokeStyle = 'black';
    ctx.clearRect(0,0,canvas.width,canvas.height);
    if (elements != null) {
      elements.forEach((item) => {
        ctx.strokeRect(item.X, item.Y, item.width, item.height);
      })
    }
  } 

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData() 
    data.append('object_id', props.id);
    data.append('X', startX);
    data.append('Y', startY);
    data.append('position', position);
    data.append('name', name);

    if (edit) {
      let putData = {
        X: startX,
        Y: startY,
        position: position,
        name: name,
      }

      axios.put(baseApiUrl + 'api/element/' + editId, putData, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }).then((response) => {
          window.location.reload(false);
      })
    } else {
      axios.post(baseApiUrl + 'api/element', data, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      }).then((response) => {
          window.location.reload(false);
      })
    }
  }

  const handleChangeX = (event) => {
      setStartX(event.target.value);
  }

  const handleChangeY = (event) => {
      setStartY(event.target.value);
  }

  const handleChangeName = (event) => {
      setName(event.target.value);
  }

  const handleChangePosition = (event) => {
    setPosition(event.target.value);
  }

  const handleMouseDown = (event) => {
      setIsDown(true);

      setStartX(event.nativeEvent.offsetX);
      setStartY(event.nativeEvent.offsetY);
  };
  const handleMouseUp = (event) => {
      if (isDown) {
          let X = event.nativeEvent.offsetX;
          let Y = event.nativeEvent.offsetY;
          setPosX(X - startX);
          setPosY(Y - startY);
          setIsDown(false);
      }
  };
  const handleMouseMove = (event) => {
      if (isDown) {
        let X = event.nativeEvent.offsetX;
        let Y = event.nativeEvent.offsetY;

        let canvas = document.getElementById('canvas');
        let ctx = canvas.getContext('2d');
        let left = canvas.offsetLeft;
        let top = canvas.offsetTop;
        ctx.clearRect(0,0,canvas.width,canvas.height);
        drawRectangle()
        ctx.strokeStyle = 'red';
        ctx.strokeRect(startX, startY, X - startX, Y - startY);
      }
  };

  const handleMouseOut = (event) => {
      setIsDown(false);
  };

  const handleUndo = () => {
    setEdit(false)
    setEditId(null)
  }

  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id)
  }

  const items = elements.map((item) => {
    return (
      <tr key={item.id}>
      <td>{item.id}</td>
      <td>{item.name}</td>
      <td>{item.X}</td>
      <td>{item.Y}</td>
      <td>{item.position}</td>
      <td><Link key={item.id} to={"/element/" + item.id}><Button variant="info" disabled={edit}>View</Button></Link>
      {edit && editId == item.id ? <Button variant="success" onClick={() => handleUndo()}>UNDO</Button> : <Button variant="success" disabled={edit} onClick={() => handleEdit(item.id)}>EDIT</Button>}
        <Button variant="danger" disabled={edit} onClick={() => handleDelete(item.id)}>DELETE</Button>
      </td>
    </tr>
    )
  })

  return (
    <>
          <div style={{ backgroundColor: 'white', padding: 40 + 'px' }}>
          <Row>
          <Col md={12}><Row><table className="table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>X</th>
                <th>Y</th>
                <th>Position</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{items}</tbody>
          </table></Row></Col>
        <Col md={12}>
            <canvas 
                id="canvas" 
                style={{border: '1px solid black'}} 
                width={object != null ? object.width : 0} 
                height={object != null ? object.height : 0} 
                onMouseDown={handleMouseDown} 
                onMouseOut={handleMouseOut} 
                onMouseMove={handleMouseMove} 
                onMouseUp={handleMouseUp}>
            </canvas>
            </Col>

      </Row>
          </div>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">X</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeX}
                  type="number"
                  value={startX}
                  />
              </InputGroup>
          </Col>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Y</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeY}
                  type="number"
                  value={startY}
                  />
              </InputGroup>
          </Col>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Position</InputGroup.Text>
                  <Form.Control
                    onChange={handleChangePosition}
                    type="number"
                    value={position}
                  />
              </InputGroup>
          </Col>
          <Col md={4}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeName}
                  type="text"
                  value={name}
                  />
              </InputGroup>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          {edit ? 'Update Element' : 'Create Element'}
        </Button>
        <Link to={object != null ? '/group/' + object.object_groups_id : ''}><Button variant="secondary">Back</Button></Link>
      </Form>
    </>
  );
}
