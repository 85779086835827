import React from 'react';
import { Button, InputGroup, Form, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams } from "react-router-dom";

const UpdateViewport = (props) => {
    const [posX, setPosX] = React.useState(0);
    const [posY, setPosY] = React.useState(0);
    const [name, setName] = React.useState('');
    const [viewport, setViewport] = React.useState([]);

    React.useEffect(() => {
        axios.get(baseApiUrl + 'api/viewport/' + props.id, {
            headers: {
                Authorization: 'Bearer ' + props.token,
              }
        })
        .then((response) => {
            let data = response.data;     
            setName(data.name);
            setPosX(data.width)
            setPosY(data.height)
        })
    }, [])

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = {
            width: posX,
            height: posY,
            name: name,
        }

        axios.put(baseApiUrl + 'api/viewport/' + props.id, data, {
            headers: {
              Authorization: 'Bearer ' + props.token,
            }
          })
        .then((response) => {
            window.location.href = '/'
        })
    }

    const handleChangeWidth = (event) => {
        setPosX(event.target.value);
    }

    const handleChangeHeight = (event) => {
        setPosY(event.target.value);
    }

    const handleChangeName = (event) => {
        setName(event.target.value);
    } 

    return (
    <>
    <Form onSubmit={handleSubmit}>
        <Row>
        <Col md={2}>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">Width</InputGroup.Text>
                <Form.Control
                    onChange={handleChangeWidth}
                    type="number"
                    value={posX}
                />
            </InputGroup>
        </Col>
        <Col md={2}>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">Height</InputGroup.Text>
                <Form.Control
                onChange={handleChangeHeight}
                type="number"
                value={posY}
                />
            </InputGroup>
        </Col>
        <Col md={4}>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                <Form.Control
                onChange={handleChangeName}
                type="text"
                value={name}
                />
            </InputGroup>
        </Col>
        </Row>

        <Button variant="primary" type="submit">
        Update Viewport
      </Button>
      <Link to={'/'}><Button variant="secondary">Back</Button></Link>
    </Form>

    </>
  );
};

export default UpdateViewport;
