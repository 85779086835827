import React from 'react'
import baseApiUrl from '../utils/baseApiUrl';
import 'bootstrap/dist/css/bootstrap.min.css';
import {FormGroup, FormLabel, Form, Row, Col, FormControl, Button, Alert} from 'react-bootstrap';
import axios from 'axios';

const Login = () => {
    const [email, setEmail] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [error, setError] = React.useState(false)
    console.log(baseApiUrl)

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.defaults.withCredentials = true;

        const data = new FormData() 
        data.append('email', email);
        data.append('password', password);

        let headers = {Accept: 'application/json'}

        axios.get(baseApiUrl + 'sanctum/csrf-cookie').then(res => {
            axios.post(baseApiUrl + 'api/login', data, headers)
            .then((response) => {
                setError(false)
                let token = response.data.token
                window.localStorage.setItem('token', token)
                window.location.href = '/'
            })
            .catch((error) =>
                setError(true)
            )
        });
    }

    const handleChangeEmail = (event) => {
        let email = event.target.value
        setEmail(email)
    }

    const handleChangePassword = (event) => {
        let password = event.target.value
        setPassword(password)
    }
    let alert = null
    if (error) {
       alert =  <Alert  variant='danger'>
        Bad email or password
      </Alert>
    }

  return (
    <div className='loginPage'>
    <div className="outer">
    <div className="inner">
    <Form className="loginForm" onSubmit={handleSubmit} >
        <h1>Sign in</h1>
        {alert}
                <FormGroup as={Row}>
                    <FormLabel column sm={2}>E-mail:</FormLabel>
                    <Col sm={10}>
                        <FormControl name="email" type="email" onChange={handleChangeEmail} value={email}/>
                    </Col>
                </FormGroup>
                <FormGroup as={Row}>
                    <FormLabel column sm={2}>Password:</FormLabel>
                    <Col sm={10}>
                        <FormControl name="password" type="password" onChange={handleChangePassword} value={password}/>
                    </Col>
                </FormGroup>
                <div className="submitButton"><Button variant="primary" type="Submit">Log in</Button></div>
            </Form>
    </div></div></div>
  );
}

export default Login;
