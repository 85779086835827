import React from 'react';
import { Button, Card, Row, Col ,InputGroup, Form} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Group(props) {
  const [viewPort, setViewPort] = React.useState(null);
  const [isDown, setIsDown] = React.useState(false);
  const [startX, setStartX] = React.useState(0);
  const [startY, setStartY] = React.useState(0);
  const [posX, setPosX] = React.useState(0);
  const [posY, setPosY] = React.useState(0);
  const [name, setName] = React.useState('')
  const [defaultObject, setDefault] = React.useState(false)
  const [components, setComponents] = React.useState(null);
  const [objects, setObjects] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(null)

  React.useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get(baseApiUrl + 'sanctum/csrf-cookie')
  })

  React.useEffect(() => {
    drawRectangle()
    if (props.id != undefined && viewPort == null) {
      axios.get(baseApiUrl + 'api/group/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let viewportId = response.data.mockup_view_port_id

        axios.get(baseApiUrl + 'api/viewport/' + viewportId, {
          headers: {
            Authorization: 'Bearer ' + props.token,
          }
        })
        .then((res) => {
          setViewPort(res.data);
        })
      })

      axios.get(baseApiUrl + 'api/group/' + props.id + '/object', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;
        setObjects(data);
      })
    }
  });

  React.useEffect(() => {
      if(document.getElementById("canvas") != null){  
        drawRectangle()
        let canvas = document.getElementById('canvas');
        let ctx = canvas.getContext('2d');
        ctx.strokeStyle = 'red';
        ctx.strokeRect(startX, startY,  posX, posY);
    }
  }, [startX, startY, posX, posY, name])
  const handleDelete = (id) => {
    axios.defaults.withCredentials = true;
    axios.get(baseApiUrl + 'sanctum/csrf-cookie')
    .then(() => {
      axios.delete(baseApiUrl + 'api/object/' + id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })
    })
  }

  const drawRectangle = () => {
    let canvas = document.getElementById('canvas');
    let ctx = canvas.getContext('2d');
    ctx.clearRect(0,0,canvas.width,canvas.height);
    ctx.strokeStyle = 'black';
    
    if (objects != null) {
      objects.forEach((item) => {
        ctx.strokeRect(item.X, item.Y, item.width, item.height);
      })
    }
  } 

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData() 
    data.append('object_groups_id', props.id);
    data.append('X', startX);
    data.append('Y', startY);
    data.append('width', posX);
    data.append('height', posY);
    data.append('name', name);
    data.append('default', defaultObject ? 1 : 0)
    if (edit) {
      let putData = {
        X: startX,
        Y: startY,
        width: posX,
        height: posY,
        name: name,
        default: defaultObject ? 1: 0
      }

      axios.put(baseApiUrl + 'api/object/' + editId, putData, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })

    } else {
   
      axios.post(baseApiUrl + 'api/object', data, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })
    }
  }

  const handleChangeX = (event) => {
      setStartX(event.target.value);
  }

  const handleChangeY = (event) => {
      setStartY(event.target.value);
  }

  const handleChangeWidth = (event) => {
      setPosX(event.target.value);
  }

  const handleChangeHeight = (event) => {
      setPosY(event.target.value);
  }

  const handleChangeName = (event) => {
      setName(event.target.value);
  }

  const handleChangeDefault = (event) => {
    setDefault(event.target.checked);
  }
  const handleMouseDown = (event) => {
      setIsDown(true);

      setStartX(event.nativeEvent.offsetX);
      setStartY(event.nativeEvent.offsetY);
  };
  const handleMouseUp = (event) => {
      if (isDown) {
          let X = event.nativeEvent.offsetX;
          let Y = event.nativeEvent.offsetY;
          setPosX(X - startX);
          setPosY(Y - startY);
          setIsDown(false);
      }
  };
  const handleMouseMove = (event) => {
      if (isDown) {
        let X = event.nativeEvent.offsetX;
        let Y = event.nativeEvent.offsetY;

        let canvas = document.getElementById('canvas');
        let ctx = canvas.getContext('2d');
        let left = canvas.offsetLeft;
        let top = canvas.offsetTop;
        ctx.clearRect(0,0,canvas.width,canvas.height);
        drawRectangle()
        ctx.strokeStyle = 'red';
        ctx.strokeRect(startX, startY, X - startX, Y - startY);
      }
  };

  const handleMouseOut = (event) => {
      setIsDown(false);
  };

  const handleUndo = () => {
    setEdit(false)
    setEditId(null)
  }

  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id)
  }

  const elements = objects.map((item) => {
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td>{item.X}</td>
        <td>{item.Y}</td>
        <td>{item.width}</td>
        <td>{item.height}</td>
        <td>{item.default ? 'Yes' : 'No'}</td>
        <td><Link key={item.id} to={"/object/" + item.id}><Button variant="info" disabled={edit} >View</Button></Link>
        {edit && editId == item.id ? <Button variant="success" onClick={() => handleUndo()}>UNDO</Button> : <Button variant="success" disabled={edit} onClick={() => handleEdit(item.id)}>EDIT</Button>}
        <Button variant="danger" disabled={edit} onClick={() => handleDelete(item.id)}>DELETE</Button>
        </td>
      </tr>
    )
  })

  return (
    <>
          <div style={{ backgroundColor: 'white', padding: 40 + 'px' }}>
          <Row>
          <Col md={12}><Row>
       <table className="table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>X</th>
                <th>Y</th>
                <th>Width</th>
                <th>Height</th>
                <th>Default</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
            
          </table>
         </Row></Col>
        <Col md={12}>
            <canvas 
                id="canvas" 
                style={{border: '1px solid black'}} 
                width={viewPort != null ? viewPort.width : 0} 
                height={viewPort != null ? viewPort.height : 0} 
                onMouseDown={handleMouseDown} 
                onMouseOut={handleMouseOut} 
                onMouseMove={handleMouseMove} 
                onMouseUp={handleMouseUp}>
            </canvas>
            </Col>
      </Row>
          </div>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">X</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeX}
                  type="number"
                  value={startX}
                  />
              </InputGroup>
          </Col>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Y</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeY}
                  type="number"
                  value={startY}
                  />
              </InputGroup>
          </Col>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Width</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeWidth}
                  type="number"
                  value={posX}
                  />
              </InputGroup>
          </Col>
          <Col md={2}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Height</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeHeight}
                  type="number"
                  value={posY}
                  />
              </InputGroup>
          </Col>
          <Col md={4}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeName}
                  type="text"
                  value={name}
                  />
              </InputGroup>
          </Col>
          <Col md={4}>
          <InputGroup>
            <label style={{paddingTop: 10 + 'px'}}><input type="checkbox" onChange={handleChangeDefault}/> Set object as default</label>
            
          </InputGroup>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
          {edit ? 'Update Object' : 'Create Object'}
        </Button>
        <Link to={viewPort != null ? '/viewport/' + viewPort.id : ''}><Button variant="secondary">Back</Button></Link>
      </Form>
    </>
  );
}
