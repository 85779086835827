import React from 'react';
import AddViewport from '../../../components/addViewport';
import Layout from '../../../components/Layout';

const Mockup = () => {
  const [token, setToken] = React.useState(null)
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
      if (window) { 
         let t = window.localStorage.getItem('token')
         
         if (t == null) {
             window.location.href = '/login'
         } else {
              setToken(t)
              setShow(true)
         }

      }
  }, []);

  let mockups = (
    <Layout>
      <h1>Viewport</h1>
      <AddViewport token={token}/>
    </Layout>
  )

  return (
    <>{show ? mockups : null}</>
  );

};

export default Mockup;
