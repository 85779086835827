import React from 'react';
import UpdateViewportComponent from '../components/updateViewportComponent';
import Layout from '../components/Layout';
import { Link, useParams } from "react-router-dom";

const UpdateViewport = () => {
  const [token, setToken] = React.useState(null)
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
      if (window) { 
         let t = window.localStorage.getItem('token')
         
         if (t == null) {
             window.location.href = '/login'
         } else {
              setToken(t)
              setShow(true)
         }

      }
  }, []);

  let id = useParams().id

  let content = (
    <Layout title="Viewport">
              <h1>Update Viewport</h1>
    <UpdateViewportComponent token={token} id={id} />
  </Layout>
  )

  return (
    <>{show ? content : null}</>
  );

};

export default UpdateViewport;
