import React from 'react';
import { Button, Card, Row, Col ,InputGroup, Form} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import { Link, useParams } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Group(props) {
  const [viewPort, setViewPort] = React.useState(null);
  const [name, setName] = React.useState('')
  const [groups, setGroups] = React.useState([]);
  const [edit, setEdit] = React.useState(false);
  const [editId, setEditId] = React.useState(null)

  React.useEffect(() => {
    if (props.id != undefined && viewPort == null) {
      axios.get(baseApiUrl + 'api/viewport/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        setViewPort(response.data);
      })

      axios.get(baseApiUrl + 'api/viewport/' + props.id + '/group', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;

        setGroups(data);
      })
    }
  });

  const handleEdit = (id) => {
    setEdit(true);
    setEditId(id)
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData() 

    if (edit) {
      data.append('name', name);

      axios.put(baseApiUrl + 'api/group/' + editId, {name: name}, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })
    } else {
      data.append('mockup_view_port_id', props.id);
      data.append('name', name);
      axios.post(baseApiUrl + 'api/group', data, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
          window.location.reload(false);
      })
    }
  }

  const handleChangeName = (event) => {
      setName(event.target.value);
  }

  const handleDelete = (id) => {
    axios.delete(baseApiUrl + 'api/group/' + id, {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    })
    .then((response) => {
        window.location.reload(false);
    })
  }
  const handleUndo = () => {
    setEdit(false)
    setEditId(null)
  }
  const elements = groups.map((item) => {
    return (
      <tr key={item.id}>
        <td>{item.id}</td>
        <td>{item.name}</td>
        <td><Link key={item.id} to={"/group/" + item.id}><Button variant="info" disabled={edit} >View</Button></Link>
        {edit && editId == item.id ? <Button variant="success" onClick={() => handleUndo()}>UNDO</Button> : <Button variant="success" disabled={edit} onClick={() => handleEdit(item.id)}>EDIT</Button>}
        <Button variant="danger" disabled={edit} onClick={() => handleDelete(item.id)}>DELETE</Button>
        </td>
      </tr>
    )
  })

  return (
    <>
          <div style={{ backgroundColor: 'white', padding: 40 + 'px' }}>
          <Row>
          <Col md={12}><Row>
       <table className="table-bordered table-striped table-hover">
            <thead>
              <tr>
                <th>Id</th>
                <th>Name</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>{elements}</tbody>
            
          </table>
         </Row></Col>
      </Row>
          </div>

      <Form onSubmit={handleSubmit}>
        <Row>
          <Col md={4}>
              <InputGroup>
                  <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                  <Form.Control
                  onChange={handleChangeName}
                  type="text"
                  value={name}
                  />
              </InputGroup>
          </Col>
        </Row>

        <Button variant="primary" type="submit">
         {edit ? 'Update Selected Group' : 'Create Object Group'}
        </Button>
        <Link to={'/'}><Button variant="secondary">Back</Button></Link>
      </Form>
    </>
  );
}
