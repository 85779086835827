import logo from './logo.svg';
import './App.css';
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Redirect,
} from "react-router-dom";
import React from 'react'
import Test from './pages/test'
import ViewportList from './pages/mockup/index'
import AddViewport from './pages/mockup/detail/add-viewport'
import Viewport from './pages/viewport'
import Group from './pages/group'
import Object from './pages/object'
import Element from './pages/element'
import Attribute from './pages/attribute'
import UpdateViewport from './pages/update-viewport'
import Login from './pages/login'
import axios from 'axios'
import baseApiUrl from './utils/baseApiUrl';
import './styles/global.scss'

function App() {
  const [token, setToken] = React.useState(null)

  React.useEffect(() => {
    axios.defaults.withCredentials = true;
    axios.get(baseApiUrl + 'sanctum/csrf-cookie')
  }, []);

  return (
    <Router>
      <Routes>
          <Route path="/" element={<ViewportList />}/>
          <Route path="/test" element={<Test />}/>
          <Route path="/viewport" element={<ViewportList />}/>
          <Route path="/add-viewport" element={<AddViewport />}/>
          <Route path="/viewport/:id" element={<Viewport />}/>
          <Route path="/group/:id" element={<Group />}/>
          <Route path="/object/:id" element={<Object />}/>
          <Route path="/element/:id" element={<Element />}/>
          <Route path="/attribute/:id" element={<Attribute />}/>
          <Route path="/update-viewport/:id" element={<UpdateViewport />}/>
          <Route path="/login" element={<Login/>}/>
      </Routes>
    </Router>
  );
}

export default App;
