import React from 'react';
import { Link, useParams } from "react-router-dom";
import Layout from '../../components/Layout';
import AttributeComponent from '../../components/attribute';

const Object = () => {
  const [token, setToken] = React.useState(null)
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
      if (window) { 
         let t = window.localStorage.getItem('token')
         
         if (t == null) {
             window.location.href = '/login'
         } else {
              setToken(t)
              setShow(true)
         }

      }
  }, []);

  let id = useParams().id;

  let content = (
    <Layout title="Viewport">
    <AttributeComponent token={token} id={id} />
  </Layout>
  )

  return (
    <>{show ? content : null}</>
  );
};

export default Object;
