import React from 'react';
import { Link } from "react-router-dom";
import baseApiUrl from '../utils/baseApiUrl';
import { Button, Card } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import axios from 'axios';

const Viewport = (props) => {
    const handleDelete = () => {
      axios.defaults.withCredentials = true;
      const headers = {
        'Authorization': 'Bearer ' + props.token
      }

        axios.delete(baseApiUrl + 'api/viewport/' + props.id, {headers})
        .then((response) => {
            window.location.reload(false);
        })
    }

  return (
      <>
        <Card style={{ width: '18rem' }}>
        <Card.Body>
            <Card.Title>{props.name}</Card.Title>
            <Card.Text>
              <div>Width: {props.width}</div>
              <div>Height: {props.height}</div>
            </Card.Text>
            <Link to={"/viewport/" + props.id}>
                <Button variant="info">View</Button>
            </Link>
            <Link to={"/update-viewport/" + props.id}><Button variant="success">UPDATE</Button></Link>
            <Button variant="danger" onClick={handleDelete}>DELETE</Button>

        </Card.Body>
        </Card>
      </>
  )
};


export default Viewport;

