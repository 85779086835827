import React from 'react';
import Layout from '../../components/Layout';
import ObjectComponent from '../../components/object';
import { Link, useParams } from "react-router-dom";

const Object = () => {
  const [token, setToken] = React.useState(null)
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
      if (window) { 
         let t = window.localStorage.getItem('token')
         
         if (t == null) {
             window.location.href = '/login'
         } else {
              setToken(t)
              setShow(true)
         }

      }
  }, []);

  let id = useParams().id
  let content = (
    <Layout title="Viewport">
    <ObjectComponent token={token} id={id} />
  </Layout>
  )
  return (
    <>{show ? content : null}</>
  );
};

export default Object;
