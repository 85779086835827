import React from 'react';
import { Button, Card, Row, Col ,InputGroup, Form} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import { Link, useParams } from "react-router-dom";
import { FileUploader } from "react-drag-drop-files";
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Attribute(props) {
  const [element, setAttribute] = React.useState(null);
  const [components, setComponents] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [defaultObject, setDefault] = React.useState(false)

  const handleChangeFile = (targetfile)  => {
    setFile(targetfile);
  };

  const handleChangeDefault = (event) => {
    setDefault(event.target.checked);
  }

  const handleDefault = (id) => {
    axios.put(baseApiUrl + 'api/image/' + id, {default: 1}, {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    })
    .then((response) => {
        window.location.reload(false);
    })
  }

  React.useEffect(() => {
    if (props.id != undefined && element == null) {
      axios.get(baseApiUrl + 'api/attribute/' + props.id, {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        setAttribute(response.data);
      })

      axios.get(baseApiUrl + 'api/attribute/' + props.id + '/image', {
        headers: {
          Authorization: 'Bearer ' + props.token,
        }
      })
      .then((response) => {
        let data = response.data;
        const items = data.map((item) => {
            let url = item.image;
            url = url.replace('public','storage');

          return(<Card key={item.id} style={{ width: '12rem' }}>
  <Card.Img variant="top" src={baseApiUrl + url} />
  <Card.Title>{item.default ? 'Default' : null}</Card.Title>
  <Card.Body>
    {!item.default ?  <Button variant="success" onClick={() => handleDefault(item.id)}>SET DEFAULT</Button> : null}
    <Button variant="danger" onClick={() => handleDelete(item.id)}>Delete</Button>
  </Card.Body>
</Card>)
        })

        setComponents(items);
      })
    }
  });

  const handleDelete = (id) => {
    axios.delete(baseApiUrl + 'api/image/' + id, {
      headers: {
        Authorization: 'Bearer ' + props.token,
      }
    })
    .then((response) => {
        window.location.reload(false);
    })
    .catch((error) => {

    })
  }
    const handleSubmit = (event) => {
        event.preventDefault();

        if (file != null) {
            const data = new FormData() 
            data.append('image', file);
            data.append('element_attribute_id', props.id)
            data.append('default', defaultObject ? 1 : 0)

            axios.post(baseApiUrl + 'api/image', data, {
              headers: {
                Authorization: 'Bearer ' + props.token,
              }
            })
            .then((response) => {
                window.location.reload(false);
            })
            .catch((error) => {

            })
        }
    }
  const fileTypes = ["PNG"];

  return (
    <>
          <div style={{ backgroundColor: 'white', padding: 40 + 'px' }}>
              <h1>{element ? element.name : ''}</h1>
          <Row>
       <Col md={12}><Row>{components}</Row></Col>
      </Row>
          </div>
    <Form onSubmit={handleSubmit}>
      <Row>
         <Col md={6}>
          <Form.Group controlId="formFile" className="mb-3">
                        <FileUploader 
                            handleChange={handleChangeFile} 
                            name="file" 
                            types={fileTypes} 
                        />
            </Form.Group>
          </Col>
            <Col md={6}>
          <InputGroup>
            <label style={{paddingTop: 10 + 'px'}}><input type="checkbox" onChange={handleChangeDefault}/> Set object as default</label>
            
          </InputGroup>
          </Col>
          </Row>
            <Button variant="primary" type="submit">
                        Add Image
                    </Button>
            <Link to={element != null ? '/element/' + element.element_id : ''}><Button variant="secondary">Back</Button></Link>
          
      </Form>
    </>
  );
}
