import React from 'react';
import { Button, InputGroup, Form, Row, Col} from 'react-bootstrap';
import axios from 'axios';
import baseApiUrl from '../utils/baseApiUrl';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link, useParams } from "react-router-dom";

const AddViewport = (props) => {
    const [mockup, setMockup] = React.useState();
    const [apiLoaded, setApiLoaded] = React.useState(false)
    const [isDown, setIsDown] = React.useState(false);
    const [posX, setPosX] = React.useState(0);
    const [posY, setPosY] = React.useState(0);
    const [name, setName] = React.useState('');

    const handleSubmit = (event) => {
        event.preventDefault();
        const data = new FormData() 
        data.append('width', posX);
        data.append('height', posY);
        data.append('name', name);

        axios.post(baseApiUrl + 'api/viewport', data, {
            headers: {
              Authorization: 'Bearer ' + props.token,
            }
          })
        .then((response) => {
            window.location.href = '/'
        })
    }

    const handleChangeWidth = (event) => {
        setPosX(event.target.value);
    }

    const handleChangeHeight = (event) => {
        setPosY(event.target.value);
    }

    const handleChangeName = (event) => {
        setName(event.target.value);
    }

    let id = useParams().id;
    if (id != undefined && !apiLoaded) {

        let u = baseApiUrl + 'api/mockup/' + id;
        const response = axios.get(u, {
            headers: {
              Authorization: 'Bearer ' + props.token,
            }
          });
        let data = response.then((r) => {setMockup(r.data)})

        setApiLoaded(true);
    }


    let url;
    if (mockup != undefined) {
        url = mockup.image;
        url = url.replace('public','storage');
    }
  

    return (
    <>
    <Form onSubmit={handleSubmit}>
        <Row>
        <Col md={2}>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">Width</InputGroup.Text>
                <Form.Control
                 onChange={handleChangeWidth}
                type="number"
                value={posX}
                />
            </InputGroup>
        </Col>
        <Col md={2}>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">Height</InputGroup.Text>
                <Form.Control
                onChange={handleChangeHeight}
                type="number"
                value={posY}
                />
            </InputGroup>
        </Col>
        <Col md={4}>
            <InputGroup>
                <InputGroup.Text id="basic-addon1">Name</InputGroup.Text>
                <Form.Control
                onChange={handleChangeName}
                type="text"
                value={name}
                />
            </InputGroup>
        </Col>
        </Row>

        <Button variant="primary" type="submit">
        Create Viewport
      </Button>
      <Link to={'/'}><Button variant="secondary">Back</Button></Link>
    </Form>

    </>
  );
};

export default AddViewport;
