import React from 'react';
import {Navbar, Container, Nav, NavDropdown} from 'react-bootstrap';
import baseApiUrl from '../utils/baseApiUrl';
import axios from 'axios';

const Layout = (props) => {
  const [token, setToken] = React.useState(null)
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
      if (window) { 
         let t = window.localStorage.getItem('token')
         
         if (t == null) {
             window.location.href = '/login'
         } else {
              setToken(t)
              setShow(true)
         }

      }
  }, []);
  
  const logout = () => {
    axios.delete(baseApiUrl + 'api/logout', {
      headers: {
        Authorization: 'Bearer ' + token,
      }
    })
    .finally(() => {
        window.localStorage.removeItem('token')
        window.location.href = '/login'
    })

  }
  return (
      <>
        <Navbar bg="light" expand="lg">
  <Container>
    <Navbar.Toggle aria-controls="basic-navbar-nav" />
    <Navbar.Collapse id="basic-navbar-nav">
      <Nav className="me-auto">
        <Nav.Link href="/viewport">Viewports</Nav.Link>
        <Nav.Link href="/test">Test</Nav.Link>
        <Nav.Link onClick={logout}>Logout</Nav.Link>
      </Nav>
    </Navbar.Collapse>
  </Container>
</Navbar>    
    <div id="containter">{props.children}</div>
      </>
  )
};


export default Layout;